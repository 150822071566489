import React, { createContext, useContext, useEffect, useState } from 'react';
import { IProductsFeaturesModel, IAuthToken } from '_models/common.interface';
import { IVendorProfile } from '_models/vendor.interface';
import { AppConstants } from '_constants/App.constants';
import { VendorMgmntSvc } from '_services/vendorMgmnt.service';
import { StorageProvider } from '_services/storageProvider.service';
import { AuthSvc } from '_services/auth.service';

interface IVendorDataContext {
    vendorProfile: IVendorProfile | undefined;
    tokenPayload: IAuthToken | null;
    productsFeatures: IProductsFeaturesModel | undefined;
}
const VendorDataContext = createContext<IVendorDataContext>({
    vendorProfile: undefined,
    tokenPayload: null,
    productsFeatures: undefined
});
export const useVendorDataContext = () => useContext(VendorDataContext);

interface IVendorDataProviderProps {
    children: React.ReactNode;
}
const vendorMgmntSvc = VendorMgmntSvc.getInstance();
const storageSvc = StorageProvider.getStorage();
const authSvc = AuthSvc.getInstance();
export const VendorDataProvider: React.FC<IVendorDataProviderProps> = ({ children }: IVendorDataProviderProps) => {
    const [vendorProfile, setVendorProfile] = useState<IVendorProfile | undefined>();
    const [tokenPayload, setTokenPayload] = useState<IAuthToken | null>(null);
    const [productsFeatures, setProductsFeaturesModel] = useState<IProductsFeaturesModel | undefined>();

    useEffect(() => {
        const token: string | null = storageSvc.get(AppConstants.TOKEN.AUTH);
        if (token) {
            const decodedToken: IAuthToken | null = vendorMgmntSvc.getTokenData();
            setTokenPayload(decodedToken);
            if (decodedToken?.scope?.toLowerCase() === AppConstants.ROLES.VENDOR?.toLowerCase()) {
                vendorMgmntSvc.vendorInfo().then((res: any) => {
                    if (res?.data) {
                        setVendorProfile(res.data);
                    }
                });
            }
        } else {
            // for embedded x_alkey is used
            const x_alkey = storageSvc.get(AppConstants.X_ALKEY);
            if (x_alkey) {
                vendorMgmntSvc.vendorInfo().then((res: any) => {
                    if (res?.data) {
                        setVendorProfile(res.data);
                    }
                });
            }
        }
    }, []);


    return (
        <VendorDataContext.Provider value={{ vendorProfile, tokenPayload, productsFeatures }}>
            {children}
        </VendorDataContext.Provider>
    );
};