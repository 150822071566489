import React, { FormEvent, useEffect, useRef, useState } from 'react';
import '_modules/VendorModule/_styles/Vendor.scss';
import { ITripRequest, ITripPassenger, IProductFeatureSelection, ISegmentLimit, ISelectedProductFeatures, SegmentActionType, TripType, ITripFlightSegment, ITripResponse, ICommunicationPreferences } from '_models/vendor.interface';
import { VendorConstants } from '_constants/Vendor.constants';
import { UtilSvc } from '_services/util.service';
import { VendorMgmntSvc } from '_services/vendorMgmnt.service';
import { useToast } from '_contexts/ToastContext';
import ProductFeatureSelector from '_modules/VendorModule/_components/ProductFeatureSelector/ProductFeatureSelector';
import PassengerData from '_modules/VendorModule/_components/PassengerData/PassengerData';
import FlightSegmentData from '_modules/VendorModule/_components/FlightSegmentData/FlightSegmentData';
import TripCreationResponse from '_modules/VendorModule/_components/TripCreationResponse/TripCreationResponse';
import { useVendorDataContext } from '_contexts/VendorDataContext';
import { Dropdown, } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { IChannelData } from "_models/vendor.interface";

interface ITripCreateFormProps {
    fillupData?: ITripRequest;
    displayError?: boolean;
    onResetTrip: () => void;
    showChannelTypeForTripB?: boolean;
}

const TripCreateForm: React.FC<ITripCreateFormProps> = (props: ITripCreateFormProps) => {

    const { vendorProfile } = useVendorDataContext();
    const toast = useToast();
    const vendorMgmntSvc = VendorMgmntSvc.getInstance();
    const utilSvc = UtilSvc.getInstance();

    const [trip, setTrip] = useState<ITripRequest>();
    const [tripResponse, setTripResponse] = useState<ITripResponse | null>(null);

    const flightSegmentLimit: ISegmentLimit = { max: 6, min: 1 };
    const passengerSegmentLimit: ISegmentLimit = { max: 9, min: 1 };

    const [dirtyFormFlag, setDirtyFormFlag] = useState<boolean>(false);
    const [resetFlag, setResetFlag] = useState<boolean>(false);

    const [selectedPdtFeatures, setSelectedPdtFeatures] = useState<ISelectedProductFeatures[]>([]);
    const [tripType, setTripType] = useState<TripType>();
    const [passengerCount, setPassengerCount] = useState<string>('');
    const [productIds, setProductIds] = useState<string[]>([]);
    const [channels, setChannels] = useState<IChannelData[]>([...VendorConstants.CHANNEL_DATA])

    // useEffect(() => {
    //     setupInitialTrip();
    // }, []);
    useEffect(() => {
        if (props.fillupData) fillupTripData(props.fillupData);
        else setupInitialTrip();
    }, [props.fillupData])
    useEffect(() => {
        if (vendorProfile && trip && !trip.itinerary.langCode) {
            setupLanguageCode();
            filterSubcribedChannels(channels, vendorProfile.communicationPreferences)
            // resetChannelStatus();
        }
    }, [vendorProfile])

    useEffect(() => {
        resetChannelStatus();
    }, [props.showChannelTypeForTripB])

    const setupLanguageCode = () => {
        setTrip((prevTrip) => {
            if (!prevTrip) return prevTrip;
            return {
                ...prevTrip,
                itinerary: {
                    ...prevTrip.itinerary,
                    langCode: vendorProfile?.defaultLangCode ? vendorProfile.defaultLangCode : VendorConstants.TRIP_LANGUAGE[0].code
                },
            }
        });
    }

    const resetData = () => {
        setTripResponse(null);

        setDirtyFormFlag(false);
        setResetFlag(true);
        setTimeout(() => {
            setResetFlag(false)
        }, 500);

        setSelectedPdtFeatures([]);
        setupTripType([]);
        setPassengerCount('');
        resetSelectedData();
        resetChannelStatus();
        props?.onResetTrip();
    }

    const resetSelectedData = () => {
        const data: ITripRequest = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_REQUEST));
        data.itinerary.passengers[0] = setPassengerId(data.itinerary.passengers[0]);
        data.itinerary.segments[0] = setFlightId(data.itinerary.segments[0]);
        data.itinerary.pricing = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PRICING));
        data.itinerary.commChannelPrefs = [];
        setProductIds([]);
        setTrip(data);
        setupLanguageCode();
    }

    const createTrip = () => {
        setDirtyFormFlag(true);

        if (!checkValidity()) {
            toast(`Please fill/select all mandatory fields correctly.`, 'error');
            return;
        }

        const requestPayload: ITripRequest | null = createRequestPayload();
        if (requestPayload) {
            // toast('Trip created.', 'success');
            // console.log(requestPayload);

            // **** //

            vendorMgmntSvc.createTripV2(requestPayload)
                .then((res: any) => {
                    if (res?.data) {
                        const resData: ITripResponse = res.data;
                        setTripResponse(resData);
                        const invalidCount: number = resData.invalid_segments.reduce((total, cur) => total + cur.invalidSegments.length, 0);
                        const totalCount = requestPayload.itinerary.segments.length;
                        const validCount = totalCount - invalidCount;
                        if (invalidCount) toast(`Trip creation unsuccessful for ${invalidCount} segment${invalidCount > 1 ? 's' : ''}`, 'error');
                        if (validCount) toast(`Trip creation successful for ${validCount} segment${validCount > 1 ? 's' : ''}`, 'success');
                        if (!invalidCount && !validCount) toast(`Trip creation api called`, 'warn');
                    } else {
                        console.warn(res);
                        toast('Failed to create trip. Response status is false.', 'warn');
                    }
                });
        }
    }
    const createRequestPayload = (): ITripRequest | null => {
        const selPdtFeatures = fetchSelectedPdtFeatures();
        if (trip && selPdtFeatures) {
            const requestPayload: ITripRequest = JSON.parse(JSON.stringify(trip));
            requestPayload.itinerary.passengers.forEach((passenger: ITripPassenger) => {
                delete passenger.id;
                if (!passenger.phoneNumber) passenger.phoneCode = '';
            });
            const primaryPassenger: ITripPassenger | undefined = requestPayload.itinerary.passengers.find((passenger: ITripPassenger) => passenger.primary);
            if (primaryPassenger) requestPayload.itinerary.passengers.forEach((passenger: ITripPassenger) => {
                if (!passenger.primary) {
                    if (!passenger.email || !utilSvc.checkEmailVaildity(passenger.email)) {
                        passenger.email = primaryPassenger.email;
                    }
                    if (!passenger.phoneNumber || !utilSvc.checkTripPhoneVaildity(passenger.phoneCode, passenger.phoneNumber)) {
                        passenger.phoneCode = primaryPassenger.phoneCode;
                        passenger.phoneNumber = primaryPassenger.phoneNumber;
                    };
                }
            });
            if (isTripFlightWatcherOnly()) {
                const contactPassenger: ITripPassenger = requestPayload.itinerary.passengers[0];
                delete contactPassenger.gender;
                contactPassenger.ktn = '';
                contactPassenger.dob = '';
                contactPassenger.nationality = '';
                contactPassenger.passportNo = '';
                contactPassenger.passportIssueDate = '';
                contactPassenger.passportExpDate = '';
                for (let i = 1; i < Number(passengerCount); i++) {
                    const temp: ITripPassenger = JSON.parse(JSON.stringify(contactPassenger));
                    temp.primary = false;
                    temp.phoneCode = '';
                    temp.phoneNumber = '';
                    temp.email = '';
                    requestPayload.itinerary.passengers.push(temp);
                }
            }
            requestPayload.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                if (isTripFlightWatcherOnly()) {
                    flight.pnr = '';
                    flight.passengerExtra = [];
                }
                delete flight.id;
            });
            requestPayload.itinerary.subscriptions = selPdtFeatures;
            requestPayload.itinerary.pricing = { ...requestPayload.itinerary.pricing, passengerCount: requestPayload.itinerary.passengers.length, price: Number(requestPayload.itinerary.pricing.price) };
            requestPayload.itinerary.commChannelPrefs = (props.showChannelTypeForTripB || isTripFlightWatcherOnly()) ? getSelectedChanelValues() : [];
            return requestPayload;
        }
        return null;
    }
    const checkValidity = (): boolean => {
        if (!trip) return false;
        if (!fetchSelectedPdtFeatures()) return false;
        if (!trip.itinerary.bookingno) return false;
        if (isTripFlightWatcherOnly()) {
            if (isNaN(Number(passengerCount)) || Number(passengerCount) < passengerSegmentLimit.min || Number(passengerCount) > passengerSegmentLimit.max) return false;
        }
        if (!trip.itinerary.passengers.some((passenger: ITripPassenger) => passenger.primary === true)) {
            toast('Atleast one passenger must be selected as primary', 'error');
            return false;
        }
        for (const passenger of trip.itinerary.passengers) {
            if (!passenger.firstname || !passenger.lastname) return false;
            if (passenger.primary) {
                if (!utilSvc.checkEmailVaildity(passenger.email)) return false;
                if (passenger.phoneNumber && !utilSvc.checkTripPhoneVaildity(passenger.phoneCode, passenger.phoneNumber)) return false;
            }
            if (!isTripFlightWatcherOnly()) {
                if (!passenger.gender) return false;
            }
        }
        for (const flight of trip.itinerary.segments) {
            if (!flight.airline || !flight.flightno || !flight.from || !flight.to || !flight.deptdate || !flight.depttime) return false;
            if (!isTripFlightWatcherOnly()) {
                if (!flight.pnr) return false;
            }
        }
        if (isTripFlightWatcherOnly() || props.showChannelTypeForTripB) {
            let selectedChannels = getSelectedChanelValues();
            if (!selectedChannels?.length)
                return false;
        }
        return true;
    }

    const setPassengerId = (passenger: ITripPassenger): ITripPassenger => ({ ...passenger, id: `passenger-${utilSvc.generateUniqueId()}` });
    const setFlightId = (flight: ITripFlightSegment): ITripFlightSegment => ({ ...flight, id: `flight-${utilSvc.generateUniqueId()}` });

    const setupInitialTrip = () => {
        const data: ITripRequest = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_REQUEST));
        data.itinerary.passengers[0] = setPassengerId(data.itinerary.passengers[0]);
        data.itinerary.segments[0] = setFlightId(data.itinerary.segments[0]);
        setTrip(data);
    }
    const fillupTripData = async (fillData: ITripRequest) => {
        if (!fillData.itinerary.bookingno) {
            fillData.itinerary.bookingno = "";
        }
        if (!fillData.itinerary.langCode) {
            fillData.itinerary.langCode = vendorProfile?.defaultLangCode ? vendorProfile.defaultLangCode : VendorConstants.TRIP_LANGUAGE[0].code;
        }
        if (!fillData.itinerary.passengers || fillData.itinerary.passengers.length === 0) {
            fillData.itinerary.passengers = [JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER))];
        }
        if (!fillData.itinerary.segments || fillData.itinerary.segments.length === 0) {
            fillData.itinerary.segments = [JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_FLIGHT_SEGMENT))];
        } else {
            fillData.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                if (!flight.passengerExtra) {
                    let tempArr = [];
                    for (let i = 0; i < fillData.itinerary.passengers.length; i++) {
                        tempArr.push(JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA)));
                    }
                    flight.passengerExtra = tempArr;
                }
            });
        }
        if (!fillData.itinerary.subscriptions) {
            fillData.itinerary.subscriptions = [];
        }
        if (!fillData.itinerary.pricing) {
            fillData.itinerary.pricing = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PRICING));
        }
        fillData.itinerary.segments.forEach((flight: ITripFlightSegment) => {
            if (flight?.depttime && flight.depttime.length > 5) {
                flight.depttime = flight.depttime.slice(0, 5);
            }
        });

        // const tripFlag: boolean = isTripTypeA(fillData.itinerary.subscriptions);
        // if (tripFlag) {
        //     fillData.itinerary.passengers = setupContactPassenger(fillData.itinerary.passengers);
        // }

        const productIdsArray: string[] = [];
        fillData.itinerary.subscriptions.forEach((sub: ISelectedProductFeatures) => {
            sub.features.forEach((f) => {
                productIdsArray.push(`${sub.product}_${f}`)
            });
        })
        setProductIds(productIdsArray);

        for (let i = 0; i < fillData.itinerary.passengers.length; i++) {
            fillData.itinerary.passengers[i] = setPassengerId(fillData.itinerary.passengers[i]);
            fillData.itinerary.passengers[i].primary = false;
            if (!fillData.itinerary.passengers[i].address) {
                fillData.itinerary.passengers[i].address = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_ADDRESS));
            }
        }
        fillData.itinerary.passengers[0].primary = true;

        for (let i = 0; i < fillData.itinerary.segments.length; i++) {
            fillData.itinerary.segments[i] = setFlightId(fillData.itinerary.segments[i]);
        }

        setTrip(fillData);

        if (props.displayError) setDirtyFormFlag(true);
    }

    const handleUpdateBookingDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        data.itinerary.bookingno = e.target.value;
        setTrip(data);
    }
    const handleUpdatePricingDetails = (e: React.ChangeEvent<HTMLInputElement>, type: 'currency-code' | 'price') => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const value = e.target.value;
        switch (type) {
            case 'currency-code':
                data.itinerary.pricing.currencyCode = value;
                break;
            case 'price':
                data.itinerary.pricing.price = value;
                break;
        }
        setTrip(data);
    }
    const handlePassengerCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassengerCount(e.target.value);
    }
    const handleUpdateTripLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        data.itinerary.langCode = e.target.value;
        setTrip(data);
        // setTrip(prev => {
        //     if (!prev) return prev;
        //     return {...prev}
        // });
    }

    const handlePassengerSegment = (type: SegmentActionType, passengerInfo: { index: number, count: number }) => {
        const { index, count } = passengerInfo;
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const passengers: ITripPassenger[] = data.itinerary.passengers;
        switch (type) {
            case 'add':
                if (passengers?.length >= passengerSegmentLimit.max) {
                    toast(`The maximum passenger limit of ${passengerSegmentLimit.max} has been reached.`, 'error');
                    return;
                }
                let newPassengers = [];
                let newPassengersExtra: any[] = [];
                for (let i = 0; i < count; i++) {
                    const passenger: ITripPassenger = setPassengerId(JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER)));
                    newPassengers.push(passenger);
                    newPassengersExtra.push(JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA)))
                }
                toast(`Passenger has been added.`, 'success');
                passengers.splice(index + 1, 0, ...newPassengers);
                data.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                    flight?.passengerExtra?.splice(index + 1, 0, ...newPassengersExtra);
                });
                break;
            case 'remove':
                if (passengers?.length <= passengerSegmentLimit.min) {
                    toast(`At least ${passengerSegmentLimit.min} passenger must be present.`, 'error');
                    return;
                }
                toast(`Passenger has been removed.`, 'error');
                passengers.splice(index, 1);
                data.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                    flight?.passengerExtra?.splice(index, 1);
                });
                break;
        }
        setTrip(data);
    }
    const handleUpdatePassenger = (passenger: ITripPassenger, index: number) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const passengers: ITripPassenger[] = data.itinerary.passengers;
        if (passengers?.at(index)) {
            passengers[index] = passenger;
            setTrip(data);
        }
    }

    const handleFlightSegment = (type: SegmentActionType, index: number) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const flights: ITripFlightSegment[] = data.itinerary.segments;
        switch (type) {
            case 'add':
                if (flights?.length >= flightSegmentLimit.max) {
                    toast(`The maximum flight limit of ${flightSegmentLimit.max} has been reached.`, 'error');
                    return;
                }
                toast(`Flight segment has been added.`, 'success');
                const flight: ITripFlightSegment = setFlightId(JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_FLIGHT_SEGMENT)));
                for (let i = 1; i < data.itinerary.passengers.length; i++) {
                    flight?.passengerExtra?.push(JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA)));
                }
                flights.splice(index + 1, 0, flight);
                break;
            case 'remove':
                if (flights?.length <= flightSegmentLimit.min) {
                    toast(`At least ${flightSegmentLimit.min} flight must be present.`, 'error');
                    return;
                }
                toast(`Flight has been removed.`, 'error');
                flights.splice(index, 1);
                break;
        }
        setTrip(data);
    }
    const handleUpdateFlight = (flight: ITripFlightSegment, index: number) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const flights: ITripFlightSegment[] = data.itinerary.segments;
        if (flights?.at(index)) {
            flights[index] = flight;
            setTrip(data);
        }
    }

    const handleProductFeatureSelection = (data: IProductFeatureSelection[]) => {
        const selectedProductFeatures: ISelectedProductFeatures[] = data
            .filter(product => product.selected)
            .map(product => ({
                product: product.prodCode,
                features: product.features
                    .filter(feature => feature.selected)
                    .map(feature => feature.featureCode)
            }));
        setSelectedPdtFeatures(selectedProductFeatures);
        setupTripType(selectedProductFeatures);
    }
    const fetchSelectedPdtFeatures = (): ISelectedProductFeatures[] | null => {
        const hasSelectedFeatures = selectedPdtFeatures.some(item => item.features.length > 0);
        return (hasSelectedFeatures) ? selectedPdtFeatures : null;
    }
    const setupTripType = (selectedProductFeatures: ISelectedProductFeatures[]) => {
        if (isTripTypeA(selectedProductFeatures)) {
            setTripType('A');
            const data: ITripRequest = JSON.parse(JSON.stringify(trip));
            // let passenger = JSON.parse(JSON.stringify(VendorConstants.assignTripPrimaryPassenger()));
            // passenger = setPassengerId(passenger);
            // data.itinerary.passengers = [passenger];
            // data.itinerary.segments.forEach((flight: ITripFlightSegment) => {
            //     flight.passengerExtra = [JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA))];
            // });
            data.itinerary.passengers = setupContactPassenger(data.itinerary.passengers);
            setTrip(data);
        }
        else setTripType('B');
    }
    const isTripTypeA = (selectedProductFeatures: ISelectedProductFeatures[]): boolean => {
        // return (selectedProductFeatures.length === 1 && selectedProductFeatures[0].product === "FW" && selectedProductFeatures[0].features.length === 1 && selectedProductFeatures[0].features[0] === "N");

        const productIds: string[] = [];
        selectedProductFeatures.forEach(pf => {
            pf.features.forEach(f => productIds.push(`${pf.product}_${f}`));
        })
        return (productIds.length === 1 && productIds[0] === 'FW_N');
    }
    const setupContactPassenger = (passengers: ITripPassenger[]): ITripPassenger[] => {
        setPassengerCount(passengers.length.toString());
        return [JSON.parse(JSON.stringify(passengers[0]))];
    }
    const isTripFlightWatcherOnly = () => (tripType === 'A');

    const findSelectedCount = (channelData: IChannelData[]): number => {
        if (!channelData || !channelData.length) return 0;
        return channelData.filter((ch: IChannelData) => ch.selected).length;
    }

    const resetChannelStatus = () => {
        const tempChannels = channels.map(channel => ({ ...channel, selected: false }));
        setChannels(tempChannels)
    }

    const filterSubcribedChannels = (channels: IChannelData[], communicationPreferences: ICommunicationPreferences[]) => {
        const validChannelNames = communicationPreferences.map(
            (preference) => preference.channel.name
        );
        let updatedChannels: IChannelData[] = channels.filter((channel) => validChannelNames.includes(channel.value));
        setChannels(updatedChannels)
    }

    const handleChannelChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value, checked } = e.target;
        const tempChannels = [...channels]
        tempChannels[index].selected = checked;
        setChannels(tempChannels);
    }
    const getSelectedChanelValues = (): string[] => {
        return channels.filter(channel => channel.selected).map(channel => channel.value);
    };

    const renderBookingDetails = () => {
        return (
            <div className='row'>
                {
                    !!trip &&
                    <>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`trip-bookingno`}>Booking number<b className='text-error'>*</b></label>
                                <input
                                    name='bookingno'
                                    type='text'
                                    id={`trip-bookingno`}
                                    value={trip.itinerary.bookingno}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateBookingDetails(e)}
                                    required
                                />
                                {
                                    (dirtyFormFlag && !trip.itinerary.bookingno) &&
                                    <div className='text-error'>Please fill out the booking number.</div>
                                }
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`trip-language`}>Language</label>
                                <select
                                    name='language'
                                    id='trip-language'
                                    value={trip.itinerary.langCode}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleUpdateTripLanguage(e)}
                                >
                                    {VendorConstants.TRIP_LANGUAGE.map((l) => <option key={l.code} value={l.code} disabled={l.disabled}>
                                        {l.label}
                                    </option>)}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`trip-pricing-price`}>Price</label>
                                <input
                                    name='pricing-price'
                                    type='number'
                                    id={`trip-pricing-price`}
                                    value={trip.itinerary.pricing.price}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdatePricingDetails(e, 'price')}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`trip-pricing-currency-code`}>Currency Code</label>
                                <input
                                    name='pricing-currency-code'
                                    type='text'
                                    id={`trip-pricing-currency-code`}
                                    value={trip.itinerary.pricing.currencyCode}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdatePricingDetails(e, 'currency-code')}
                                />
                            </div>
                        </div>
                    </>
                }
                {
                    isTripFlightWatcherOnly() &&
                    <>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`trip-noofpsngrs`}>No. of passengers<b className='text-error'>*</b></label>
                                <input
                                    name='passenger-count'
                                    type='number'
                                    id={`trip-passenger-count`}
                                    min={passengerSegmentLimit.min}
                                    max={passengerSegmentLimit.max}
                                    value={passengerCount}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassengerCountChange(e)}
                                    required
                                />
                                {
                                    (dirtyFormFlag && !passengerCount) &&
                                    <div className='text-error'>Please fill out the number of passengers.</div>
                                }
                                {
                                    (dirtyFormFlag && !!passengerCount && (Number(passengerCount) < passengerSegmentLimit.min || Number(passengerCount) > passengerSegmentLimit.max)) &&
                                    <div className='text-error'>Number of passengers cannot be less than {passengerSegmentLimit.min} or more than {passengerSegmentLimit.max}.</div>
                                }
                            </div>
                        </div>
                    </>
                }
                {
                    (props.showChannelTypeForTripB || isTripFlightWatcherOnly()) &&
                    <>
                        <div className='col-md-4 products-features-container'>
                            <div>Channel type<b className='text-error'>*{props.showChannelTypeForTripB && <span className='ins-text'>only applicable for FW only</span>}</b></div>
                            <Dropdown className='cust-multi-select-dropdown' id="channel-selection">
                                <Dropdown.Toggle className='dropdown-btn'>
                                    <span>
                                        {
                                            findSelectedCount(channels) > 0 ? `${findSelectedCount(channels)} types selected` : 'Select'
                                        }
                                    </span>
                                    <span className='chevron'><FaChevronDown /></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        channels.map((item: IChannelData, i: number) => {
                                            return (
                                                <div key={'channel-type-' + item.value} className='product-list'>
                                                    <input
                                                        type="checkbox"
                                                        id={'channel-id-' + item.value}
                                                        name={item.value}
                                                        value={item.value}
                                                        checked={item.selected}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChannelChange(e, i)}
                                                    />
                                                    <label htmlFor={'channel-id-' + item.value}>{item.label}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            {
                                (dirtyFormFlag && (isTripFlightWatcherOnly() || props.showChannelTypeForTripB) && getSelectedChanelValues()?.length <= 0) &&
                                <div className='text-error'>Please select at least 1 channel.</div>
                            }
                        </div>
                    </>
                }

            </div>
        );
    }

    return (
        <div className='trip-create-form-container'>
            <div className='form-wrapper'>
                <form onSubmit={(e: FormEvent) => { e.preventDefault() }}>
                    <div className='row'>

                        <div className='col-md-12'>
                            <div className='form-wrapper'>
                                <div className='cust-form-label'>Product/Feature Details</div>
                                <div className="row">
                                    <div className="col-md-4 products-features-container">
                                        <div>Feature type<b className='text-error'>*</b></div>
                                        <ProductFeatureSelector
                                            displayFeature={true}
                                            resetFlag={resetFlag}
                                            productIds={productIds}
                                            onSelectProductFeature={(data: IProductFeatureSelection[]) => handleProductFeatureSelection(data)}
                                        />
                                        {
                                            (dirtyFormFlag && !fetchSelectedPdtFeatures()) &&
                                            <div className='text-error'>Please select at least 1 feature.</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 padding-top'>
                            <div className='form-wrapper'>
                                <div className='cust-form-label'>Booking Details</div>
                                {renderBookingDetails()}
                            </div>
                        </div>

                        <div className='col-md-12 padding-top'>
                            <div className='passengers-container form-wrapper'>
                                <div className='cust-form-label'>
                                    {isTripFlightWatcherOnly() ? 'Contact' : 'Passenger'} Details
                                </div>
                                <div className='container-fluid flight-segment-container'>
                                    {
                                        (!!trip && !!trip.itinerary?.passengers && !!tripType) &&
                                        trip.itinerary.passengers.map((passenger: ITripPassenger, i: number) => (
                                            <React.Fragment key={passenger.id}>
                                                <PassengerData
                                                    index={i}
                                                    singular={isTripFlightWatcherOnly()}
                                                    tripType={tripType}
                                                    isTripFlightWatcherOnly={isTripFlightWatcherOnly()}
                                                    passenger={passenger}
                                                    dirtyFormFlag={dirtyFormFlag}
                                                    resetFlag={resetFlag}
                                                    onPassengerSegmentChange={(type: SegmentActionType) => { handlePassengerSegment(type, { index: i, count: 1 }) }}
                                                    onUpdatePassenger={(passenger: ITripPassenger) => { handleUpdatePassenger(passenger, i) }}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 padding-top'>
                            <div className='passengers-container form-wrapper'>
                                <div className='cust-form-label'>Flight Segment Details</div>
                                <div className='container-fluid flight-segment-container'>
                                    {
                                        (!!trip && !!trip.itinerary?.segments && !!tripType) &&
                                        trip.itinerary.segments.map((flight: ITripFlightSegment, i: number) => (
                                            <React.Fragment key={flight.id}>
                                                <FlightSegmentData
                                                    index={i}
                                                    tripType={tripType}
                                                    isTripFlightWatcherOnly={isTripFlightWatcherOnly()}
                                                    flight={flight}
                                                    passengers={trip.itinerary.passengers}
                                                    dirtyFormFlag={dirtyFormFlag}
                                                    resetFlag={resetFlag}
                                                    onFlightSegmentChange={(type: SegmentActionType) => { handleFlightSegment(type, i) }}
                                                    onUpdateFlight={(flight: ITripFlightSegment) => { handleUpdateFlight(flight, i) }}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 padding-top'>
                            <div className='btn-grp-wrapper'>
                                <button type='button' className='button-primary' onClick={resetData}>
                                    Reset
                                </button>
                                <button type='submit' onClick={createTrip} className='button-primary'>
                                    Create
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

            {
                !!tripResponse &&
                <div className='padding-top'>
                    <TripCreationResponse
                        tripResponse={tripResponse}
                    />
                </div>
            }
        </div>
    );
};

export default TripCreateForm;