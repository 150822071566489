import { AppConstants } from "_constants/App.constants";
import { ApiService } from "./api.service";
import { UrlProviderService } from "./urlProvider.service";
import { ICountrySearch, IAirlineSearch, IAirportSearch, ILocalStorageAirline, ILocalStorageAirport, ILocalStorageCountry } from "_models/common.interface";
import { IStorage } from "_models/storage.interface";
import { StorageProvider } from "./storageProvider.service";

export class RefDataSvc extends UrlProviderService {
    private static instance: RefDataSvc;
    private airlineList: IAirlineSearch[] = [];
    private airportList: IAirportSearch[] = [];
    private countryList: ICountrySearch[] = [];
    private dataFetched: Promise<void>;

    private constructor() {
        super('refdata-api');
        this.dataFetched = this.initializeData();
    }
    public static getInstance(): RefDataSvc {
        if (!this.instance) {
            this.instance = new RefDataSvc();
        }
        return this.instance;
    }
    private async initializeData(): Promise<void> {
        await Promise.all([
            this.fetchAllAirlines(),
            this.fetchAllAirports(),
            this.fetchAllCountry()
        ]);
    }

    private apiSvc: ApiService = ApiService.getInstance(process.env.REACT_APP_BASE_URL);
    private storageSvc: IStorage = StorageProvider.getStorage();

    searchCountry(data: string) {
        const uri = this.generateUrl(`countries/search?term=${data}`);
        return this.apiSvc.get(uri, { setLoader: false });
    }
    searchAirline(data: string) {
        const uri = this.generateUrl(`airlines/search?term=${data}`);
        return this.apiSvc.get(uri, { setLoader: false });
    }
    fetchSearchAirlineURL(data: string): string {
        const uri = `${process.env.REACT_APP_BASE_URL}refdata-api/airlines/search?term=${data}`;
        return uri;
    }
    searchAirport(data: string) {
        const uri = this.generateUrl(`airports/search?term=${data}`);
        return this.apiSvc.get(uri, { setLoader: false });
    }

    getAllAirlines() {
        const uri = this.generateUrl(`airlines/all`);
        return this.apiSvc.get(uri, { setLoader: false });
    }
    getAllAirports() {
        const uri = this.generateUrl(`airports/all`);
        return this.apiSvc.get(uri, { setLoader: false });
    }
    getAllCountry() {
        const uri = this.generateUrl(`/countries/all`);
        return this.apiSvc.get(uri, { setLoader: false });
    }

    searchAirlineByCode(code: string) {
        const uri = this.generateUrl(`airlines/code/${code?.toUpperCase()}`);
        return this.apiSvc.get(uri, { setLoader: false });
    }

    private async fetchAllAirlines(): Promise<void> {
        const key: string = AppConstants.LOCAL_STORAGE_KEY.ALL_AIRLINES;
        const data: ILocalStorageAirline | null = this.storageSvc.get<ILocalStorageAirline>(key);
        const tsLimit = Date.now() - 1000 * 60 * 60 * 24 * 7;

        if (data && data.airlines && data.creationTs > tsLimit && data.airlines.length > 0) {
            this.airlineList = data.airlines;
            return;
        }

        try {
            const res = await this.getAllAirlines();
            if (res?.data) {
                const resData: IAirlineSearch[] = res.data;
                const lsData: ILocalStorageAirline = { airlines: resData, creationTs: Date.now() };
                this.storageSvc.put(key, lsData);
                this.airlineList = resData;
            } else {
                this.airlineList = [];
            }
        } catch (err) {
            console.error(err);
            this.airlineList = [];
        }
    }
    async getAirlineDetails(airlineCodes: string): Promise<IAirlineSearch | null> {
        await this.dataFetched;
        const airlineDetails: IAirlineSearch | undefined = this.airlineList.find((airline: IAirlineSearch) => airlineCodes.includes(airline.code));
        return airlineDetails || null;
    }

    private async fetchAllAirports(): Promise<void> {
        const key: string = AppConstants.LOCAL_STORAGE_KEY.ALL_AIRPORTS;
        const data: ILocalStorageAirport | null = this.storageSvc.get<ILocalStorageAirport>(key);
        const tsLimit = Date.now() - 1000 * 60 * 60 * 24 * 7;

        if (data && data.airports && data.creationTs > tsLimit && data.airports.length > 0) {
            this.airportList = data.airports;
            return;
        }

        try {
            const res = await this.getAllAirports();
            if (res?.data) {
                const resData: IAirportSearch[] = res.data;
                const lsData: ILocalStorageAirport = { airports: resData, creationTs: Date.now() };
                this.storageSvc.put(key, lsData);
                this.airportList = resData;
            } else {
                this.airportList = [];
            }
        } catch (err) {
            console.error(err);
            this.airportList = [];
        }
    }
    async getAirportDetails(airportCodes: string): Promise<IAirportSearch | null> {
        await this.dataFetched;
        const airportDetails: IAirportSearch | undefined = this.airportList.find((airport: IAirportSearch) => airportCodes.includes(airport.code));
        return airportDetails || null;
    }

    private async fetchAllCountry(): Promise<void> {
        const key: string = AppConstants.LOCAL_STORAGE_KEY.ALL_COUNTRY;
        const data: ILocalStorageCountry | null = this.storageSvc.get<ILocalStorageCountry>(key);

        if (data && data.country && data.country.length > 0) {
            this.countryList = data.country;
            return;
        }

        try {
            const res = await this.getAllCountry();
            if (res?.data) {
                const resData: ICountrySearch[] = res.data;
                const lsData: ILocalStorageCountry = { country: resData };
                this.storageSvc.put(key, lsData);
                this.countryList = resData;
            } else {
                this.countryList = [];
            }
        } catch (err) {
            console.error(err);
            this.countryList = [];
        }
    }
}