
import createTheme from "@mui/material/styles/createTheme";

export class AppConstants {
    // local stroage key
    public static TOKEN = {
        AUTH: 'auth_token',
        REFRESH: 'refresh_token',
        DATA: 'data',
    }
    
    public static X_ALKEY: string = 'x_alkey';

    public static ERROR_TEXT = {
        PAGE_NOT_FOUND: "Page Not Found!",
        SOMETHING_WENT_WRONG: "Something Went Wrong!"
    }

    // {[role: string]: string}
    public static OP_ROLE = {
        ROLE: 'OPERATOR',
        PATH: 'checkin',
        ADMIN: 'ADMIN',
        ADMIN_LEAD: 'ADMIN_LEAD',
        STATUS1: 'STATUS1',
    }
    // public static OP_TYPE = {
    //     OPERATOR: 'OPERATOR',
    //     OPERATOR_ADMIN: 'OPERATOR_ADMIN',
    //     OPERATOR_ADMIN_LEAD: 'OPERATOR_ADMIN_LEAD',
    // }
    public static ROLES: any = {
        PLATFORM_ADMIN: 'admin',
        VENDOR: 'vendor',
        OPERATOR: this.OP_ROLE.PATH,
        OPERATOR_ADMIN: this.OP_ROLE.PATH
    }

    public static IMAGE_PATH: string = '/images/icons/';


    public static theme = createTheme({
        palette: {
            primary: {
                main: '#e7c418',
            }
        },
    });
    public static themeFG48 = createTheme({
        palette: {
            primary: {
                main: '#00a3d4',
            }
        },
    });

    // local stroage key
    public static LOCAL_STORAGE_KEY = {
        PRODUCTS_FEATURES_DATA: 'productsFeaturesData',
        COUNTRY_DATA: 'countryData',
        ALL_AIRLINES: 'allAirlines',
        ALL_AIRPORTS: 'allAirports',
        ALL_COUNTRY: 'allCountry',
        UPCOMING_TRIPS_DISPLAY_TYPE: 'upcomingTripsDisplayType',
        LOGGED_OUT: 'loggedOut',
        LO_NOTIFICATIONS: 'loNotifications',
        IS_NEXT_ENABLED: 'isNextEnabled',
    }
}