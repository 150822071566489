import React, { useState, useEffect, useRef } from 'react';
import '_modules/VendorModule/_styles/Vendor.scss';
import debounce from 'lodash/debounce';
import { useToast } from '_contexts/ToastContext';
import { ITripFlightSegment, ITripPassenger, ITripPassengerExtra, SegmentActionType, TripType } from '_models/vendor.interface';
import { IAirlineSearch, IAirportSearch, AirportType } from '_models/common.interface';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Tooltip } from '@mui/material';
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { RefDataSvc } from '_services/refData.service';

interface ITripFlightSegmentDataProps {
    index: number;
    tripType: TripType;
    isTripFlightWatcherOnly: boolean;
    flight: ITripFlightSegment;
    passengers: ITripPassenger[];
    dirtyFormFlag: boolean;
    resetFlag: boolean;
    onFlightSegmentChange: (type: SegmentActionType) => void;
    onUpdateFlight: (flight: ITripFlightSegment) => void;
}

const FlightSegmentData: React.FC<ITripFlightSegmentDataProps> = (props: ITripFlightSegmentDataProps) => {
    const { isTripFlightWatcherOnly, dirtyFormFlag, onFlightSegmentChange } = props;
    const toast = useToast();
    const refDataSvc = RefDataSvc.getInstance();

    const [flight, setFlight] = useState<ITripFlightSegment>()

    const [airlineList, setAirlineList] = useState<IAirlineSearch[]>([]);
    const [fromList, setFromList] = useState<IAirportSearch[]>([]);
    const [toList, setToList] = useState<IAirportSearch[]>([]);

    const [defaultAirlineList, setDefaultAirlineList] = useState<IAirlineSearch[]>([]);
    const [defaultFromList, setDefaultFromList] = useState<IAirportSearch[]>([]);
    const [defaultToList, setDefaultToList] = useState<IAirportSearch[]>([]);

    const airlineRef = useRef<any>(null);
    const fromRef = useRef<any>(null);
    const toRef = useRef<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (props.flight) {
                const data: ITripFlightSegment = props.flight;
                if (data.airline) await updateDefaultAirlineList(data.airline);
                if (data.from) await updateDefaultAirportList(data.from, 'from');
                if (data.to) await updateDefaultAirportList(data.to, 'to');
                setFlight(data);
                // setFlight(prev => {
                //     return { ...data, passengerExtra: prev?.passengerExtra ? prev.passengerExtra : [] };
                // });
            }
        }

        fetchData();
    }, [])
    useEffect(() => {
        if (props.flight) {
            setFlight(prev => {
                if (prev) return { ...prev, passengerExtra: props.flight?.passengerExtra }
                return prev;
            });
        }
    }, [props.passengers.length])
    useEffect(() => {
        if (flight) props.onUpdateFlight(flight);
    }, [flight]);
    useEffect(() => {
        if (props.resetFlag) {
            resetData();
        }
    }, [props.resetFlag]);

    const resetData = () => {
        // console.log("reset");
    }

    const updateDefaultAirlineList = async (code: string) => {
        const al = await refDataSvc.getAirlineDetails(code);
        if (al) {
            setDefaultAirlineList([al]);
            setAirlineList([al]);
            return;
        }

        const res = await refDataSvc.searchAirline(code);
        if (res?.data) {
            const airlineData = res.data.find((airline: IAirlineSearch) => [airline.code, airline.icao].includes(code));
            if (airlineData) {
                setDefaultAirlineList([airlineData]);
                setAirlineList(res.data);
            }
        }
    }
    const updateDefaultAirportList = async (code: string, type: AirportType) => {
        const ap = await refDataSvc.getAirportDetails(code);
        if (ap) {
            if (type === 'from') {
                setDefaultFromList([ap]);
                setFromList([ap]);
            }
            if (type === 'to') {
                setDefaultToList([ap]);
                setToList([ap]);
            }
            return;
        }

        const res = await refDataSvc.searchAirport(code);
        if (res?.data) {
            const airportData = res.data.find((airport: IAirportSearch) => [airport.code, airport.icao].includes(code));
            if (airportData) {
                if (type === 'from') {
                    setDefaultFromList([airportData]);
                    setFromList(res.data);
                }
                if (type === 'to') {
                    setDefaultToList([airportData]);
                    setToList(res.data);
                }
            }
        }
    }

    const handleDataChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, type: 'other' | 'checkbox' | 'date' | 'time') => {
        if (flight) {
            switch (type) {
                case 'other':
                case 'date':
                case 'time':
                    {
                        let { name, value } = e.target;
                        // console.log(value);
                        // if (type === 'date') value = utilSvc.formatDate2(value, 'yyyy-mm-dd');
                        // if (type === 'time') value = utilSvc.formatTime2(value);
                        setFlight({ ...flight, [name]: value });
                    }
                    break;
                case 'checkbox':
                    {
                        const { name, checked } = e.target as HTMLInputElement;
                        setFlight({ ...flight, [name]: checked });
                    }
                    break;
            }
        }
    }
    const handleExtraPassengerDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (flight) {
            let { name, value } = e.target;
            const data: ITripFlightSegment = JSON.parse(JSON.stringify(flight));
            if (data.passengerExtra?.at(index)) {
                data.passengerExtra[index][name as keyof ITripPassengerExtra] = value;
                setFlight(data);
            }
        }
    }

    const debouncedSearchAirlines = debounce((query: string) => {
        handleSearchAirlines(query);
    }, 300);
    const handleSearchAirlines = (query: string) => {
        refDataSvc.searchAirline(query)
            .then((res: any) => {
                if (res?.data) {
                    setAirlineList(res?.data);
                } else {
                    toast('Failed to search airlines. Response status is false.', 'warn');
                    console.warn(res);
                }
            })
            .catch((err: any) => {
                toast(`${err.message}. Failed to search airlines.`, 'error');
                console.error(err);
            });
    }
    const handleChangeAirline = (selected: IAirlineSearch[]) => {
        if (flight) {
            if (selected?.at(0)?.code) {
                setFlight({ ...flight, airline: selected[0].code });
                airlineRef?.current?.blur();
            } else {
                setFlight({ ...flight, airline: '' });
            }
        }
    }
    const handleBlurAirlineTypeahead = () => {
        if (flight && !flight.airline) {
            airlineRef?.current?.clear();
        }
    }

    const dbouncedSearchAirports = debounce((query: string, type: AirportType) => {
        handleSearchAirports(query, type);
    }, 300);
    const handleSearchAirports = (query: string, type: AirportType) => {
        refDataSvc.searchAirport(query)
            .then((res: any) => {
                if (res?.data) {
                    if (type === 'from') {
                        setFromList(res?.data);
                    }
                    if (type === 'to') {
                        setToList(res?.data);
                    }
                } else {
                    toast('Failed to search airports. Response status is false.', 'warn');
                    console.warn(res);
                }
            })
            .catch((err: any) => {
                toast(`${err.message}. Failed to search airports.`, 'error');
                console.error(err);
            });
    }
    const handleChangeAirport = (selected: IAirportSearch[], type: AirportType) => {
        if (flight) {
            if (selected?.at(0)?.code) {
                setFlight({ ...flight, [type === 'from' ? 'from' : 'to']: selected[0].code });
                if (type === 'from') {
                    fromRef?.current?.blur();
                }
                if (type === 'to') {
                    toRef?.current?.blur();
                }
            } else {
                setFlight({ ...flight, [type === 'from' ? 'from' : 'to']: '' });
            }
        }
    }
    const handleBlurAirportTypeahead = (type: AirportType) => {
        if (type === 'from') {
            if (flight && !flight.from) {
                fromRef?.current?.clear();
            }
        }
        if (type === 'to') {
            if (flight && !flight.to) {
                toRef?.current?.clear();
            }
        }
    }

    const renderPassengerExtraSegment = (extra: ITripPassengerExtra, i: number) => {
        return (
            (!!flight && props?.passengers?.at(i)?.id) &&
            <div className='item-wrapper form-wrapper form-wrapper-2'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='text-weight-m' style={{ paddingBottom: '5px' }}>
                            Passenger {i + 1}
                        </div>
                    </div>

                    {/* <div className='col-md-12'>
                        {props.passengers[i].id}
                    </div> */}

                    <div className='col-md-4'>
                        <div className='cust-form-group'>
                            <label htmlFor={`seg-extra-${flight.id}`}>E-ticket</label>
                            <input
                                name='eticket'
                                type='text'
                                id={`seg-extra-passenger-${flight.id}-${props.passengers[i].id}`}
                                value={extra?.eticket ? extra.eticket : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleExtraPassengerDataChange(e, i)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                !!flight &&
                <div className='item-wrapper form-wrapper form-wrapper-1'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='data-header'>
                                <div className='text-weight-m'>
                                    Flight Segment {props.index + 1}
                                </div>
                                <div className='btn-grp-wrapper add-delete-btn-grp'>
                                    <Tooltip title={`Remove flight segment ${props.index + 1}`} arrow onClick={() => onFlightSegmentChange('remove')}>
                                        <span><FaTimesCircle className='text-error text-l' /></span>
                                    </Tooltip>
                                    <Tooltip title={`Add flight segment`} arrow onClick={() => onFlightSegmentChange('add')}>
                                        <span><FaPlusCircle className='text-success text-l' /></span>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-md-12'>
                            {flight.id}
                        </div> */}

                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label>Airline (autocomplete)<b className='text-error'>*</b></label>
                                <AsyncTypeahead
                                    ref={airlineRef}
                                    filterBy={() => true}
                                    id={`seg-airline-${flight.id}`}
                                    className='typeahead'
                                    isLoading={false}
                                    // labelKey='name'
                                    labelKey={(option: any) => `${option.code} - ${option.name}`}
                                    minLength={2}
                                    onSearch={(query: string) => debouncedSearchAirlines(query)}
                                    onChange={(selected: any[]) => handleChangeAirline(selected)}
                                    onBlur={() => handleBlurAirlineTypeahead()}
                                    options={airlineList}
                                    defaultSelected={defaultAirlineList}
                                    placeholder=''
                                    renderMenuItemChildren={(airline: any) => (
                                        <>
                                            <span>{airline.code} - {airline.name}</span>
                                        </>
                                    )}
                                />
                                {
                                    (!!dirtyFormFlag && !flight.airline) &&
                                    <div className='text-error'>Please fill out the airline.</div>
                                }
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`seg-flightno-${flight.id}`}>Flight number<b className='text-error'>*</b></label>
                                <input
                                    name='flightno'
                                    type='text'
                                    id={`seg-flightno-${flight.id}`}
                                    value={flight.flightno}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'other')}
                                    required
                                />
                                {
                                    (!!dirtyFormFlag && !flight.flightno) &&
                                    <div className='text-error'>Please fill out the flight number.</div>
                                }
                            </div>
                        </div>

                        {
                            isTripFlightWatcherOnly ?
                                <div className='col-md-4'></div>
                                :
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`seg-pnr-${flight.id}`}>PNR<b className='text-error'>*</b></label>
                                        <input
                                            name='pnr'
                                            type='text'
                                            id={`seg-pnr-${flight.id}`}
                                            value={flight.pnr}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'other')}
                                            required
                                        />
                                        {
                                            (!!dirtyFormFlag && !flight.pnr) &&
                                            <div className='text-error'>Please fill out the pnr.</div>
                                        }
                                    </div>
                                </div>
                        }


                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label>From airport (autocomplete)<b className='text-error'>*</b></label>
                                <AsyncTypeahead
                                    ref={fromRef}
                                    filterBy={() => true}
                                    id={`seg-from-airport-${flight.id}`}
                                    className='typeahead'
                                    isLoading={false}
                                    labelKey={(option: any) => ` ${option.code} - ${option.name}`}
                                    minLength={2}
                                    useCache={false}
                                    onSearch={(query: string) => dbouncedSearchAirports(query, 'from')}
                                    onChange={(selected: any[]) => handleChangeAirport(selected, 'from')}
                                    onBlur={() => handleBlurAirportTypeahead('from')}
                                    options={fromList}
                                    defaultSelected={defaultFromList}
                                    placeholder=''
                                    renderMenuItemChildren={(airport: any) => (
                                        <>
                                            <span>{airport.code} - {airport.name} - {airport.city_code}</span>
                                        </>
                                    )}
                                />
                                {
                                    (!!dirtyFormFlag && !flight.from) &&
                                    <div className='text-error'>Please fill out the from airport.</div>
                                }
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label>To airport (autocomplete)<b className='text-error'>*</b></label>
                                <AsyncTypeahead
                                    ref={toRef}
                                    filterBy={() => true}
                                    id={`seg-to-airport-${flight.id}`}
                                    className='typeahead'
                                    isLoading={false}
                                    labelKey={(option: any) => `${option.code} - ${option.name}`}
                                    minLength={2}
                                    useCache={false}
                                    onSearch={(query: string) => dbouncedSearchAirports(query, 'to')}
                                    onChange={(selected: any[]) => handleChangeAirport(selected, 'to')}
                                    onBlur={() => handleBlurAirportTypeahead('to')}
                                    options={toList}
                                    defaultSelected={defaultToList}
                                    placeholder=''
                                    renderMenuItemChildren={(airport: any) => (
                                        <>
                                            <span>{airport.code} - {airport.name} - {airport.city_code}</span>
                                        </>
                                    )}
                                />
                                {
                                    (!!dirtyFormFlag && !flight.to) &&
                                    <div className='text-error'>Please fill out the to airport.</div>
                                }
                            </div>
                        </div>
                        <div className='col-md-4'></div>

                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`seg-deptdate-${flight.id}`}>Departure date<b className='text-error'>*</b></label>
                                <input
                                    name='deptdate'
                                    type='date'
                                    id={`seg-deptdate-${flight.id}`}
                                    // min={utilSvc.formatDate2((new Date()).toString(), 'yyyy-mm-dd')}
                                    // max={}
                                    value={flight.deptdate}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'date')}
                                    required
                                />
                                {
                                    (!!dirtyFormFlag && !flight.deptdate) &&
                                    <div className='text-error'>Please fill out the departure date.</div>
                                }
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='cust-form-group'>
                                <label htmlFor={`seg-depttime-${flight.id}`}>Departure time<b className='text-error'>*</b></label>
                                <input
                                    name='depttime'
                                    type='time'
                                    id={`seg-depttime-${flight.id}`}
                                    value={flight.depttime}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'time')}
                                    required
                                />
                                {
                                    (!!dirtyFormFlag && !flight.depttime) &&
                                    <div className='text-error'>Please fill out the departure time.</div>
                                }
                            </div>
                        </div>

                        {
                            !isTripFlightWatcherOnly &&
                            <div className='col-md-12'>
                                {
                                    flight?.passengerExtra.map((extra: ITripPassengerExtra, i: number) => (
                                        <React.Fragment key={props?.passengers?.at(i)?.id ? props.passengers[i].id : i}>
                                            {renderPassengerExtraSegment(extra, i)}
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div >
            }
        </>
    );
};

export default FlightSegmentData;