import React, { useEffect, useRef, useState } from 'react';
import '_modules/VendorModule/_styles/Vendor.scss';
import TripCreateForm from '_modules/VendorModule/_components/TripCreateForm/TripCreateForm';
import { ISelectedProductFeatures, ITripFlightSegment, ITripPassenger, ITripPassengerExtra, ITripRequest } from '_models/vendor.interface';
import { VendorConstants } from '_constants/Vendor.constants';

interface ITripCreateProps { }

const TripCreate: React.FC<ITripCreateProps> = (props: ITripCreateProps) => {
    const [trip, setTrip] = useState<ITripRequest>();

    useEffect(() => {
        fetchQueryParams();
    }, []);

    const fetchQueryParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramsbody = {
            productid: urlParams.get('productids'),
            totalPrice: urlParams.get('totalPrice'),
            currencyCode: urlParams.get('currencyCode'),
            passengers: urlParams.get('passengers')
        }
        const hasValue = Object.values(paramsbody).some(value => value !== null && value !== undefined && value !== '');

        if (hasValue) {
            const tripData: ITripRequest = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_REQUEST));
            // console.log(VendorConstants.DEFAULT_TRIP_REQUEST);

            if (paramsbody.productid) {
                const productIds = paramsbody.productid.replace('[', '').replace(']', '').split(',');
                tripData.itinerary.subscriptions = transformProductFeatures(productIds);
            }
            if (paramsbody.totalPrice) {
                tripData.itinerary.pricing.price = paramsbody.totalPrice
            }
            if (paramsbody.currencyCode) {
                tripData.itinerary.pricing.currencyCode = paramsbody.currencyCode
            }
            if (paramsbody.passengers && !isNaN(Number(paramsbody.passengers))) {
                const count: number = Number(paramsbody.passengers);
                const passengerArr: ITripPassenger[] = [];
                const passengerExtraArr: ITripPassengerExtra[] = [];
                for (let i = 1; i < count; i++) {
                    passengerArr.push(JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER)));
                    passengerExtraArr.push(JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA)));
                }
                // console.log([...tripData.itinerary.passengers, ...passengerArr]);
                tripData.itinerary.passengers = JSON.parse(JSON.stringify([...tripData.itinerary.passengers, ...passengerArr]));
                tripData.itinerary.segments.forEach((seg: ITripFlightSegment) => {
                    seg.passengerExtra = [...seg.passengerExtra, ...passengerExtraArr];
                });
            }

            // console.log(tripData);
            setTrip(JSON.parse(JSON.stringify(tripData)));
        }
    }

    const transformProductFeatures = (productIds: string[]): ISelectedProductFeatures[] => {
        return productIds.reduce((acc, item) => {
            const [product, feature] = item.split('_');

            const existingProduct = acc.find(p => p.product === product);
            if (existingProduct) {
                if (!existingProduct.features.includes(feature)) existingProduct.features.push(feature);
            } else {
                acc.push({
                    product,
                    features: [feature]
                });
            }

            return acc;
        }, [] as ISelectedProductFeatures[]);
    }

    return (
        <div className='container-fluid dashboard-container'>
            <div className='row'>
                <div className='col-md-12'>
                    <h2>Create Trip</h2>
                </div>

                <div className='col-md-12'>
                    <TripCreateForm fillupData={trip} displayError={false} onResetTrip={() => { }} />
                </div>
            </div>
        </div>
    );
};

export default TripCreate;