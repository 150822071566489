// _modules/EmbeddedModule/EmbeddedModuleRouter.tsx
import { useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { AuthSvc } from '_services/auth.service';
import TripCreate from '_modules/VendorModule/_pages/TripCreate/TripCreate';
import { ThemeProvider } from 'react-bootstrap';
import { AppConstants } from '_constants/App.constants';
import { VendorDataProvider } from '_contexts/VendorDataContext';
import LayoutDashboard from '_pages/LayoutDashboard/LayoutDashboard';

interface EmbeddedWrapperProps {
    children: React.ReactNode;
}

const EmbeddedWrapper: React.FC<EmbeddedWrapperProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const authSvc = AuthSvc.getInstance();

    useEffect(() => {
        const handleToken = () => {
            try {
                // Listen for token from parent window
                window.addEventListener('message', (event) => {
                    // Verify origin for security
                    // if (!event.origin.match(/^https?:\/\/(localhost|your-allowed-domain\.com)/)) {
                    //     return;
                    // }
                    if (event.data.type === 'X_ALKEY') {
                        const key = event.data.key;
                        // Store token and update auth state
                        authSvc.setXALKEY(key);
                        setIsAuthenticated(true);
                    }
                });

                // Request token from parent
                window.parent.postMessage({ type: 'REQUEST_KEY' }, '*');
            } catch (error) {
                console.error('Error handling embedded authentication:', error);
            }
        };

        handleToken();

        return () => {
            // Cleanup listener
            window.removeEventListener('message', handleToken);
        };
    }, []);

    if (!isAuthenticated) {
        return <div>Authenticating...</div>;
    }

    return <>{children}</>;
};

const EmbeddedModuleRouter = () => {
    return (
        <ThemeProvider theme={AppConstants.theme}>
            <VendorDataProvider>
                <Routes>
                    <Route element={<EmbeddedWrapper>
                        <Outlet />
                    </EmbeddedWrapper>}>
                        <Route path="/trip-create" element={<TripCreate />} />
                    </Route>
                </Routes>
            </VendorDataProvider>
        </ThemeProvider>

    );
};

export default EmbeddedModuleRouter;