import React, { useState, useEffect } from 'react';
import '_modules/VendorModule/_styles/Vendor.scss';
import { IVendorProfile, IProductFeature, IProductFeatureSelection } from '_models/vendor.interface';
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { useVendorDataContext } from '_contexts/VendorDataContext';

interface IProductFeatureSelectorProps {
    displayFeature: boolean;
    resetFlag: boolean;
    oneProduct?: boolean;
    productIds: string[];
    onSelectProductFeature: (data: IProductFeatureSelection[]) => void;
}

const ProductFeatureSelector: React.FC<IProductFeatureSelectorProps> = (props: IProductFeatureSelectorProps) => {
    const { vendorProfile } = useVendorDataContext();
    const [pdtFeatures, setPdtFeatures] = useState<IProductFeatureSelection[]>();

    useEffect(() => {
        if (vendorProfile) {
            const products: IProductFeature[] = vendorProfile.productFeatures;
            const groupedProducts: { [prodCode: string]: IProductFeature[] } = products.reduce<{ [key: string]: IProductFeature[] }>((groups, product) => {
                product.selected = false;
                (groups[product.prodCode] = groups[product.prodCode] || []).push(product);
                return groups;
            }, {});
            const productFeatureSelections: IProductFeatureSelection[] = Object.entries(groupedProducts).map(([prodCode, features]) => ({
                prodCode,
                prodName: features[0].prodName,
                selected: false,
                features,
            }));
            updatePdtFeature(productFeatureSelections);
        }
    }, [vendorProfile]);

    useEffect(() => {
        if (pdtFeatures) props.onSelectProductFeature(pdtFeatures);
    }, [pdtFeatures]);

    useEffect(() => {
        if (pdtFeatures) updatePdtFeature(pdtFeatures);
    }, [props.productIds])

    const updatePdtFeature = (productFeatureSelections: IProductFeatureSelection[]) => {
        if (props.productIds) {
            const productIds = props.productIds.map(id => id.split('_')[0]);
            productFeatureSelections.forEach(product => {
                if (productIds.includes(product.prodCode)) {
                    product.selected = true;
                    product.features.forEach(feature => {
                        if (props.productIds.includes(feature.id)) {
                            feature.selected = true;
                        }
                    });
                }
            });
        }
        setPdtFeatures(productFeatureSelections);
    }

    useEffect(() => {
        if (pdtFeatures)
            props.onSelectProductFeature(pdtFeatures);
    }, [pdtFeatures]);
    useEffect(() => {
        if (props.resetFlag) {
            resetData();
        }
    }, [props.resetFlag]);

    const resetData = () => {
        const tempPdt: IProductFeatureSelection[] = JSON.parse(JSON.stringify(pdtFeatures));
        tempPdt.forEach((pdt: IProductFeatureSelection) => {
            pdt.selected = false;
            pdt.features.forEach((feature: IProductFeature) => {
                feature.selected = false;
            });
        });
        setPdtFeatures(tempPdt);
    }

    const selectedProductsCount: number = pdtFeatures?.filter(selection => selection.selected).length || 0;
    const selectedFeaturesCount: number = pdtFeatures?.reduce((total, selection) => {
        return total + selection.features.filter(feature => feature.selected).length;
    }, 0) || 0;

    const handleProductChange = (e: React.ChangeEvent<HTMLInputElement>, indexPdt: number) => {
        const tempPdt: IProductFeatureSelection[] = JSON.parse(JSON.stringify(pdtFeatures));
        if (tempPdt?.at(indexPdt)) {
            tempPdt[indexPdt].selected = e.target.checked;
            if (tempPdt[indexPdt].selected === false) tempPdt[indexPdt].features.forEach((feature: IProductFeature) => feature.selected = false);
            setPdtFeatures(tempPdt);
            if (props.oneProduct) {
                tempPdt.forEach((pdt: IProductFeatureSelection, i: number) => {
                    if (i !== indexPdt) {
                        pdt.selected = false;
                        pdt.features.forEach((feature: IProductFeature) => feature.selected = false);
                    }
                });
            }
        }
    }
    const handleFeatureChange = (e: React.ChangeEvent<HTMLInputElement>, indexPdt: number, indexFeature: number) => {
        const tempPdt: IProductFeatureSelection[] = JSON.parse(JSON.stringify(pdtFeatures));
        if (tempPdt?.at(indexPdt)?.features?.at(indexFeature)) {
            tempPdt[indexPdt].features[indexFeature].selected = e.target.checked;
            setPdtFeatures(tempPdt);
        }
    }

    return (
        <Dropdown className='cust-multi-select-dropdown' id="product-feature-selection">
            <Dropdown.Toggle className='dropdown-btn'>
                <span className='ellipsis'>
                    {
                        !props.displayFeature ?
                            <>{selectedProductsCount > 0 ? `${selectedProductsCount} product selected` : 'Select'}</>
                            :
                            <>{selectedFeaturesCount > 0 ? `${selectedFeaturesCount} feature selected` : 'Select'}</>
                    }
                </span>
                <span className='chevron'><FaChevronDown /></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    pdtFeatures?.map((pdtFeature: IProductFeatureSelection, i: number) => {
                        return (
                            <div key={'product-type-' + pdtFeature.prodCode}>
                                <div className='product-list'>
                                    <input
                                        type="checkbox"
                                        id={'product-id-' + pdtFeature.prodCode}
                                        name={pdtFeature.prodName}
                                        checked={pdtFeature.selected}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProductChange(e, i)}
                                    />
                                    <label htmlFor={'product-id-' + pdtFeature.prodCode}>
                                        {pdtFeature.prodName}
                                    </label>
                                </div>
                                {
                                    props.displayFeature && pdtFeature.selected &&
                                    <div className='feature-wrapper'>
                                        {
                                            pdtFeature.features.map((feature: IProductFeature, j: number) => {
                                                return (
                                                    <div key={'feature-' + feature.featureCode} className='feature-list'>
                                                        <input
                                                            type="checkbox"
                                                            id={'feature-id-' + feature.featureCode}
                                                            name={feature.featureName}
                                                            checked={feature.selected}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFeatureChange(e, i, j)}
                                                        />
                                                        <label htmlFor={'feature-id-' + feature.featureCode}>
                                                            {feature.featureName}
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    );

}

export default ProductFeatureSelector;